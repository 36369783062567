import React from "react"
import { graphql } from "gatsby"

import { format } from "date-fns"
import { ru } from "date-fns/locale"
import cx from "classnames"

import Layout from "../../components/layout"
import { renderHtmlBlocks } from "../../helpers/render"

import * as styles from "./news.module.css"

const NewsTemplate = ({ data: { currentNews }, pageContext }) => {
  const date = new Date(currentNews.dateGmt)
  const formattedDate = format(date, "d MMMM yyyy 'г.'", { locale: ru })

  return (
    <Layout>
      <section className={styles.container}>
        <div className={styles.section}>
          <div className={styles.sectionItem}>
            <p className={styles.data}>{formattedDate}</p>
            <p className={styles.header}>{currentNews.title}</p>
            {currentNews.content && renderHtmlBlocks(currentNews.content)}
            <a className={cx("theme-button", styles.button)} href={"/news"}>
              К новостям
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NewsTemplate

export const pageQuery = graphql`
  query NewsById($id: String!, $language: String!) {
    currentNews: wpPost(id: { eq: $id }) {
      content
      title
      seo {
        metaDescription
        metaKeywords
        title
      }
      dateGmt
      settings {
        customStyles
        rbeaStyles
        language
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
